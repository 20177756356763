export const UPDATE_ARCHETYPE = 'UPDATE_ARCHETYPE';
export const UPDATE_CLAN = 'UPDATE_CLAN';
export const SET_RANK = 'SET_RANK';
export const SET_STARTING_DOTS = 'SET_STARTING_DOTS';
export const SET_FOCUS = 'SET_FOCUS';
export const ADD_MERIT = 'ADD_MERIT';
export const REMOVE_MERIT = 'REMOVE_MERIT';
export const ADD_FLAW = 'ADD_FLAW';
export const REMOVE_FLAW = 'REMOVE_FLAW';
export const PURCHASE_DOT = 'PURCHASE_DOT';
export const UNPURCHASE_DOT = 'UNPURCHASE_DOT';
export const PURCHASE_MORALITY_DOT = 'PURCHASE_MORALITY_DOT';
export const UNPURCHASE_MORALITY_DOT = 'UNPURCHASE_MORALITY_DOT';
export const TOGGLE_PENCIL_ERASER_MODE = 'TOGGLE_PENCIL_ERASER_MODE';
export const UPDATE_MORALITY = 'UPDATE_MORALITY';
export const UPDATE_SETTING = 'UPDATE_SETTING';
export const UPDATE_RITUALS = 'UPDATE_RITUALS';
